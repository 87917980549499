import { PREFIX } from '../constant';
import { cx } from "../utils";

const HcLink = ({ as, children, size = 'medium', target = "_self", ...props }) => {
  const Component = as || 'a';

  const classNames = `${PREFIX}__text ${PREFIX}__text--s-${size} ${PREFIX}--link`;

  if (typeof Component === 'function') {
    return (
      <Component {...props}>
        <a onClick={props.onClick} target={target} className={classNames}>{children}</a>
      </Component>
    );
  }

  return (
    <Component
        // className={classNames}
        className={cx(
            `${PREFIX}__text ${PREFIX}__text--s-${size} ${PREFIX}--link`,
            props.className
        )}
        {...props}
    >
      {children}
    </Component>
  );
};

export default HcLink;
