import { ErrorModel } from './ErrorDto';

export interface HttpOptionModel {
  isLoading?: boolean;
  isNotification?: boolean;
}

export interface PagingOutputModel {
  pageIndex: number;
  pageCount: number;
  recordCount: number;
}

export interface IResponseModel<T> {
  success: boolean;
  data: T;
  message:string;
  error: ErrorModel;
  pagingOutput: PagingOutputModel;
}

export class ResponseModel<T = {}> implements IResponseModel<T> {
  success: boolean = false;
  data: any = null;
  message: string = '';

  error: ErrorModel = new ErrorModel();

  pagingOutput: PagingOutputModel = { pageCount: 0, pageIndex: 0, recordCount: 0 };

  constructor(initialData?: T) {
    if (typeof initialData !== 'undefined') {
      this.data = initialData;
    }
  }
}
