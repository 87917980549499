import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { Button } from '../../../core/button';
import HcLink from "../../../core/layout/link";
import HcText from "../../../core/layout/text";
import Image from "next/legacy/image";

import plansCheckedIllustration from "/public/assets/icons/plans-checked.svg";
import plansUncheckedIllustration from "/public/assets/icons/plans-unchecked.svg";

const PricingOptions = React.memo((props: PropsWithChildren<any>) => {
  const [rows, setRows] = useState<string[]>([]);
  const [originalRows, setOriginalRows] = useState<string[]>([]);
  const [extraRows, setExtraRows] = useState<string[]>([]);

  const [showExtraRows, setShowExtraRows] = useState<boolean>(false);

  useEffect(() => {
    setRows(props.productPropertiesList.slice(0, props.productPropertiesList.length >= 5 ? 5 : props.productPropertiesList.length));
    setOriginalRows(props.productPropertiesList.slice(0, props.productPropertiesList.length >= 5 ? 5 : props.productPropertiesList.length));

    if (props.productPropertiesList && props.productPropertiesList.length > 5) {
      const arr = [];

      for (let i = 5; i < props.productPropertiesList.length; i++) {
        arr.push(props.productPropertiesList[i]);
      }

      setExtraRows(arr);
    }
  }, []);

  return (
    <Card className="pricing-card">
      <CardBody>
        <div className="table-responsive price-plan-table">
          <Table>
            <thead>
              <tr>
                <th />
                {props.packageWithPriceList.map((item, index) => (
                  <th
                    className={`${
                      item.id == props.selectedPackage?.id ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <div className="d-flex justify-content-center">
                      <h5>{item.name}</h5>
                    </div>
                    <div>
                      <h1>
                        <sup>
                          <span>$</span>
                        </sup>
                        {/*{item.price.toFixed(2)}*/}
                        {item.price.toFixed(0)}
                        <div className="d-flex align-items-end mb-0 font-size-12">
                          <p style={{ color: 'initial' }}>
                            {'.' + item.price.toFixed(2).split('.')[1]}
                          </p>
                        </div>
                        {/*<p>/Month</p>*/}
                      </h1>
                    </div>
                    <div>
                      <p className="mb-0">per agent/month</p>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr data-testid="xxx">
                {props.userSubscription !== null &&
                props.userSubscription.length == 0 ? (
                    <>
                      <th />
                      {props.packageWithPriceList.map((item, index) => (
                          (item.name == "Free" ? (
                              <th className='' key={index}></th>
                          ) : (
                              <th
                                  className={`${
                                      item.id == props.selectedPackage?.id ? 'active' : ''
                                  }`}
                                  key={index}
                              >
                                <Button
                                    id={`button-${item.id}`}
                                    data-testid={`package-${item.id}`}
                                    size="md"
                                    className="w-100"
                                    onClick={() => props.setSelectedPackage(item)}

                                    variant={item.id == props.selectedPackage?.id ? `primary`: `secondary`}

                                >
                                  {item.id == props.selectedPackage?.id
                                      // ? 'Proceed'
                                      ? props.selectedPackage?.name + ' Selected'
                                      // : 'Select'}
                                      : (props.selectText ? props.selectText : 'Select ' + item.name)}
                                </Button>
                              </th>
                          ))
                      ))}
                    </>
                ) : null}
              </tr>
              {/*{props.productPropertiesList.map((item, index) => (*/}
              {rows.map((item, index) => (
                <tr key={index}>
                  <th className="w-50 text-start" scope="row">
                    {item}
                  </th>
                  {props.packageWithPriceList.map((p, i) => (
                    <td
                      className={`${
                        p.id == props.selectedPackage?.id ? 'active' : ''
                      }`}
                      key={`${index}-${i}`}
                    >
                      <Image
                          src={
                              p.productProperties.includes(item)
                                  ? plansCheckedIllustration
                                  : plansUncheckedIllustration
                          }
                          width={20}
                          height={20}
                          alt={
                              p.productProperties.includes(item)
                                  ? 'plans-checked'
                                  : 'plans-unchecked'
                          }
                      />
                    </td>
                  ))}
                </tr>
              ))}
              {/*<tr data-testid="xxx">*/}
              {/*  {props.userSubscription !== null &&*/}
              {/*  props.userSubscription.length == 0 ? (*/}
              {/*    <>*/}
              {/*      <th />*/}
              {/*      {props.packageWithPriceList.map((item, index) => (*/}
              {/*        (item.name == "Free" ? (*/}
              {/*        <th className='' key={index}></th>*/}
              {/*          ) : (*/}
              {/*        <th*/}
              {/*          className={`${*/}
              {/*            item.id == props.selectedPackage?.id ? 'active' : ''*/}
              {/*          }`}*/}
              {/*          key={index}*/}
              {/*        >*/}
              {/*          <Button*/}
              {/*            id={`button-${item.id}`}*/}
              {/*            data-testid={`package-${item.id}`}*/}
              {/*            size="md"*/}
              {/*            className="w-100"*/}
              {/*            onClick={() => props.setSelectedPackage(item)}*/}
              {/*          >*/}
              {/*            {item.id == props.selectedPackage?.id*/}
              {/*              // ? 'Proceed'*/}
              {/*              ? 'Selected'*/}
              {/*              // : 'Select'}*/}
              {/*              : (props.selectText ? props.selectText : 'Select')}*/}
              {/*          </Button>*/}
              {/*        </th>*/}
              {/*        ))*/}
              {/*      ))}*/}
              {/*    </>*/}
              {/*  ) : null}*/}
              {/*</tr>*/}
            </tbody>
          </Table>
          {extraRows.length ? (
              <div className="toggle-feature-list w-100 d-flex justify-content-center">
                <HcLink
                    size="medium"
                    as={'a'}
                    onClick={() => {
                      if (!showExtraRows) {
                        setRows(rows.concat(extraRows));
                      } else {
                        setRows(originalRows);
                      }

                      setShowExtraRows(!showExtraRows);
                    }}
                >
                  <HcText
                      size="medium"
                      as={'u'}
                      style={{ whiteSpace: 'nowrap' }}
                  >
                    {!showExtraRows ? 'Complete Feature List' : 'Show Less'}
                  </HcText>
                </HcLink>
              </div>
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
});
export default PricingOptions;
