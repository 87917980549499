import { PREFIX } from '../constant';
import { cx } from '../utils';

const HcText = ({ as, children, size = 'medium', ...props }) => {
  const Component = as || 'p';

  return (
    <Component
      {...props}
      className={cx(
        `${PREFIX}__text ${PREFIX}__text--s-${size}`,
        props.className
      )}
    >
      {children}
    </Component>
  );
};

export default HcText;
