import { ErrorCode } from '../Enums/ErrorCode';
import { ErrorStatus } from '../Enums/ErrorStatus';

export interface IErrorModel {
  code: ErrorCode;
  status: ErrorStatus;
  message: string;
}

export class ErrorModel implements IErrorModel {
  code: ErrorCode = ErrorCode.Success;
  status: ErrorStatus = ErrorStatus.NONE;
  message: string = '';
}
