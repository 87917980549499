import { Account } from "../store/account";
import { setLoading } from "../store/common/commonSlice";
import { store } from "../store/store";

import { Auth } from "aws-amplify";

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import * as Sentry from "@sentry/nextjs";

import { AxiosResponse } from "axios";

import { RequestModel } from "../models/DataModels/RequestDto";
import { IResponseModel, ResponseModel } from "../models/DataModels/ResponseDto";

const APP_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || "https://www.dev.allmedicalcodes.com";

export default {
    request: async (request: RequestModel) => {
        let response: IResponseModel<any> = new ResponseModel();

        let { api, method, params, spinner, anonymous } = request;

        let loading = true;
        if (spinner !== null && spinner !== undefined)
            loading = spinner;

        if (loading)
            store.dispatch(setLoading(true));

        try {
            if (params == null)
                params = [];

            if (!params.some(param => param && param.hasOwnProperty('headers'))) {
                let headers = {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                };

                if (!anonymous) {
                    const currentSession = await Auth.currentSession();

                    headers["Authorization"] = `Bearer ${currentSession.getIdToken().getJwtToken()}`;

                    Account.token.set(currentSession.getIdToken().getJwtToken());
                    Account.refreshToken.set(currentSession.getRefreshToken().getToken());
                }

                params.push(
                    {
                        headers,
                    }
                );
            }



            const _configuration = require("../gen/openapi/configuration")

            const configuration = new _configuration['Configuration']({
                basePath: APP_BASE_URL,
            });

            const _api = require("../gen/openapi/api")

            let openApi = new _api[api](configuration)

            const axiosResponse: AxiosResponse = await openApi[method](...params);

            response = axiosResponse.data;

            // console.log('request axiosResponse', axiosResponse);
        }
        catch (error) {
            // console.log('request error', error);
            if (error.response !== undefined) {
                let errorMessage;

                switch (error.response.status) {
                    case 400:
                        errorMessage = 'Bad Request';
                        break;
                    case 401:
                        errorMessage = 'Unauthorized';
                        break;
                    case 403:
                        errorMessage = 'Forbidden';
                        break;
                    case 404:
                        errorMessage = 'Not Found';
                        break;
                    case 415:
                        errorMessage = 'Unsupported Media Type';
                        break;
                    case 500:
                        errorMessage = 'Internal Server Error';
                        break;
                    default:
                        errorMessage = 'Unknown Error';
                }

                response.message = errorMessage;

                if (error.response.data) {
                    if (error.response.data.message) {
                        if (error.response.data.message === "ModelValidationError") {
                            errorMessage = errorMessage + ' - ' + "Validation error: <br />";
                            error.response.data.data.fields?.map((field) => {
                                errorMessage = errorMessage + field.errorMessage + "<br />";
                            });
                        } else {
                            errorMessage =  errorMessage + ': ' + error.response.data.message;
                        }
                    }

                    if (error.response.data.errors) {
                        if (error.response.data.title)
                            errorMessage = errorMessage + ' - ' + error.response.data.title + "<br />";
                        else
                            errorMessage = errorMessage + ' - ' + "Validation error: <br />";

                        for (let e in error.response.data.errors) {
                            errorMessage = errorMessage + e + ': ' + "<br />";
                            error.response.data.errors[e]?.map(err => {
                                errorMessage = errorMessage + "\t - " + err + "<br />";
                            });
                        }
                    }
                }

                response.error.code = error.response.status;
                response.error.message = errorMessage;

                Sentry.captureException(error, {
                    tags: {
                        from: 'API',
                        message: errorMessage
                    }
                });

                toastr.error(errorMessage, 'Error', {
                    closeButton: true,
                    positionClass: 'toast-top-right',
                    timeOut: 3000,
                    extendedTimeOut: 1,
                });
            }
        }
        finally {
            if (loading)
                store.dispatch(setLoading(false));
        }

        // console.log('request response', response);
        return response;
    }
}

