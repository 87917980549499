import { cx } from '../utils';

const Button = ({ children, ...props }) => {
  const { variant = 'primary', size = 'md' } = props;
  return (
    <button
      {...props}
      className={cx(
        `hc__button hc__button--${variant} hc__button--size-${size}`,
        props.className
      )}
    >
      {children}
    </button>
  );
};

export { Button };
